<template>
  <div v-if="user" class="flex-grow-1">
    <preloader v-if="getting" message="Please wait..." />

    <div class="d-flex gap-20">
      <v-card max-width="220" class="shadow-lg rounded-lg mx-auto mx-sm-0">
        <v-card-text v-if="user" class="pa-3">
          <UserPhoto :id="user.userid" size="100%" tile photoSize="small" />
        </v-card-text>
      </v-card>

      <div>
        <div
          :style="{fontSize: $vuetify.breakpoint.xs ? '25px' : '35px'}"
          class="font-weight-bold heading-font text-capitalize primary--text"
        >{{ user.firstName }} {{ user.lastName }}</div>
        
        <div><a class="text-decoration-none" :href="`mailto:${user.email}`">{{ user.email }}</a></div>
        <div v-if="user.jobTitle">{{ user.jobTitle }}</div>
        
        <div v-if="school" class="mt-3">
          <router-link :to="{ name: 'SchoolsProfile', params: { id: school.id } }">View School Profile</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import db from '@/firebase/init'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      user: null,
      getting: false,
      school: null,
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getUserData() {
      this.user = null
      this.getting = true
      let id = this.$route.params.id

      db.collection('users')
      .where('userid', '==', id)
      .limit(1).get()
      .then((snapshot) => {
        if (snapshot.size) {
          let doc = snapshot.docs[0]
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          this.user = data
          this.getting = false

          db.collection('schools')
          .where('useruid', '==', data.userid)
          .limit(1).get()
          .then((schoolSnapshot) => {
            if (schoolSnapshot.size) {
              let schoolDoc = schoolSnapshot.docs[0]
              let school = schoolDoc.data()
              school.id = schoolDoc.id
              school.ref = schoolDoc.ref
              this.school = school
            }
          })
        }
      })
      .catch(error => {
        this.getting = false
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getUserData()
  }
}
</script>